import { AuthenticationService } from '../../../services/authentication.service';
import { UserService } from '../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pass',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.css'],
})
export class PassComponent implements OnInit {
  currentuid: string;
  user: any;
  uid: string;
  uid_in_userdatabase: any;

  pass1: string;
  pass2: string;
  cupass: string;
  saveresponse1: string;

  email1: string;
  email2: string;
  cupass2: string;
  saveresponse2: string;
  guardadoPass: boolean = false;
  guardadoEmail: boolean = false;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private auth: AngularFireAuth,
    private translate: TranslateService
  ) {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.currentuid = status.uid;
        this.userService.getUserById(status.uid).subscribe((data) => {
          this.user = data[0].payload.doc.data();
          this.uid_in_userdatabase = data[0].payload.doc.id;
          this.uid = this.currentuid;
        });
      });
    } else {
      this.currentuid = Userdata.payload.doc.data().uid;
      this.user = Userdata.payload.doc.data();
      this.uid_in_userdatabase = Userdata.payload.doc.id;
      this.uid = this.currentuid;
    }
  }

  async changepass() {
    if (this.cupass) {
      if (this.pass1 && this.pass2) {
        if (this.pass1 === this.pass2) {
          await (
            await this.auth.currentUser
          )
            .reauthenticateWithCredential(
              firebase.auth.EmailAuthProvider.credential(
                (
                  await this.auth.currentUser
                ).email,
                this.cupass
              )
            )
            .then(async (promise) => {
              if (promise.user.email) {
                await (
                  await this.auth.currentUser
                )
                  .updatePassword(this.pass1)
                  .then((updata) => {
                    this.translate
                      .get('dashboard.perfil.pass.response.actualizada')
                      .subscribe((data) => {
                        this.guardadoPass = true;
                        setTimeout(() => {
                          this.guardadoPass = false;
                        }, 3000);
                      });
                  })
                  .catch((error) => {
                    this.translate
                      .get('dashboard.perfil.pass.response.noActualizada')
                      .subscribe((data) => {
                        this.saveresponse1 = data;
                        setTimeout(() => {
                          this.saveresponse1 = '';
                        }, 5000);
                      });
                  });
              } else {
                this.translate
                  .get('dashboard.perfil.pass.response.incorrecta')
                  .subscribe((data) => {
                    this.saveresponse1 = data;
                    setTimeout(() => {
                      this.saveresponse1 = '';
                    }, 5000);
                  });
              }
            })
            .catch((error) => {
              this.translate
                .get('dashboard.perfil.pass.response.incorrecta')
                .subscribe((data) => {
                  this.saveresponse1 = data;
                  setTimeout(() => {
                    this.saveresponse1 = '';
                  }, 5000);
                });
            });
        } else {
          this.translate
            .get('dashboard.perfil.pass.response.coinciden')
            .subscribe((data) => {
              this.saveresponse1 = data;
              setTimeout(() => {
                this.saveresponse1 = '';
              }, 5000);
            });
        }
      } else {
        this.translate
          .get('dashboard.perfil.pass.response.vacios')
          .subscribe((data) => {
            this.saveresponse1 = data;
            setTimeout(() => {
              this.saveresponse1 = '';
            }, 5000);
          });
      }
    } else {
      this.translate
        .get('dashboard.perfil.pass.response.actual')
        .subscribe((data) => {
          this.saveresponse1 = data;
          setTimeout(() => {
            this.saveresponse1 = '';
          }, 5000);
        });
    }
  }

  // async changeemail() {
  //   if (this.cupass2) {
  //     if (this.email1 && this.email2) {
  //       if (this.email1 == this.email2) {
  //         await (
  //           await this.auth.currentUser
  //         )
  //           .reauthenticateWithCredential(
  //             firebase.auth.EmailAuthProvider.credential(
  //               (
  //                 await this.auth.currentUser
  //               ).email,
  //               this.cupass2
  //             )
  //           )
  //           .then(async (promise) => {
  //             if (promise.user.email) {
  //               await (
  //                 await this.auth.currentUser
  //               )
  //                 .updateEmail(this.email1.toLowerCase())
  //                 .then((upmail) => {
  //                   const theuser = {
  //                     uid: this.uid,
  //                     email: this.email1,
  //                     usuario:this.email1
  //                   };
  //                   this.userService.editUser(
  //                     theuser,
  //                     this.uid_in_userdatabase
  //                   );

  //                   this.translate
  //                     .get('dashboard.perfil.pass.response.actualizadoEmail')
  //                     .subscribe((data) => {
  //                       this.guardadoEmail = true
  //                       setTimeout(() => {
  //                       this.guardadoEmail=false
  //                       }, 3000);

  //                     });
  //                 })
  //                 .catch((error) => {
  //                   this.translate
  //                     .get('dashboard.perfil.pass.response.noActuEmail')
  //                     .subscribe((data) => {
  //                       this.saveresponse2 = data;
  //                       setTimeout(() => {
  //                         this.saveresponse2 = '';
  //                       }, 5000);
  //                     });
  //                 });
  //             } else {
  //               this.translate
  //                 .get('dashboard.perfil.pass.response.incorrecta')
  //                 .subscribe((data) => {
  //                   this.saveresponse2 = data;
  //                   setTimeout(() => {
  //                     this.saveresponse2 = '';
  //                   }, 5000);
  //                 });
  //             }
  //           })
  //           .catch((error) => {
  //             this.translate
  //               .get('dashboard.perfil.pass.response.incorrecta')
  //               .subscribe((data) => {
  //                 this.saveresponse2 = data;
  //                 setTimeout(() => {
  //                   this.saveresponse2 = '';
  //                 }, 5000);
  //               });
  //           });
  //       } else {
  //         this.translate
  //           .get('dashboard.perfil.pass.response.emailsCoinciden')
  //           .subscribe((data) => {
  //             this.saveresponse2 = data;
  //             setTimeout(() => {
  //               this.saveresponse2 = '';
  //             }, 5000);
  //           });

  //         this.translate
  //           .get('dashboard.perfil.pass.response.emailsCoinciden')
  //           .subscribe((data) => {
  //             this.saveresponse2 = data;
  //             setTimeout(() => {
  //               this.saveresponse2 = '';
  //             }, 5000);
  //           });
  //       }
  //     } else {
  //       this.translate
  //         .get('dashboard.perfil.pass.response.vacios')
  //         .subscribe((data) => {
  //           this.saveresponse2 = data;
  //           setTimeout(() => {
  //             this.saveresponse2 = '';
  //           }, 5000);
  //         });
  //     }
  //   } else {
  //     this.translate
  //       .get('dashboard.perfil.pass.response.actual')
  //       .subscribe((data) => {
  //         this.saveresponse2 = data;
  //         setTimeout(() => {
  //           this.saveresponse2 = '';
  //         }, 5000);
  //       });
  //   }
  // }
  async changeemail() {
    if (this.cupass2) {
      if (this.email1 && this.email2) {
        if (this.email1 === this.email2) {
          try {
            const user = await this.auth.currentUser;

            // Reautenticación
            await user.reauthenticateWithCredential(
              firebase.auth.EmailAuthProvider.credential(
                user.email,
                this.cupass2
              )
            );

            // Actualización del email en Firebase Authentication
            await user.updateEmail(this.email1.toLowerCase());

            // Actualización del email en la base de datos de usuarios
            const theuser = {
              uid: this.uid,
              email: this.email1.toLowerCase(),
              usuario: this.email1.toLowerCase(),
            };
            await this.userService.editUser(theuser, this.uid_in_userdatabase);

            // Mostrar mensaje de éxito
            this.translate
              .get('dashboard.perfil.pass.response.actualizadoEmail')
              .subscribe((data) => {
                this.guardadoEmail = true;
                setTimeout(() => {
                  this.guardadoEmail = false;
                }, 3000);
              });
          } catch (error) {
            // Manejo de errores
            if (error.code === 'auth/wrong-password') {
              this.translate
                .get('dashboard.perfil.pass.response.incorrecta')
                .subscribe((data) => {
                  this.saveresponse2 = data;
                  setTimeout(() => {
                    this.saveresponse2 = '';
                  }, 5000);
                });
            } else {
              this.translate
                .get('dashboard.perfil.pass.response.noActuEmail')
                .subscribe((data) => {
                  this.saveresponse2 = data;
                  setTimeout(() => {
                    this.saveresponse2 = '';
                  }, 5000);
                });
            }
          }
        } else {
          this.translate
            .get('dashboard.perfil.pass.response.emailsCoinciden')
            .subscribe((data) => {
              this.saveresponse2 = data;
              setTimeout(() => {
                this.saveresponse2 = '';
              }, 5000);
            });
        }
      } else {
        this.translate
          .get('dashboard.perfil.pass.response.vacios')
          .subscribe((data) => {
            this.saveresponse2 = data;
            setTimeout(() => {
              this.saveresponse2 = '';
            }, 5000);
          });
      }
    } else {
      this.translate
        .get('dashboard.perfil.pass.response.actual')
        .subscribe((data) => {
          this.saveresponse2 = data;
          setTimeout(() => {
            this.saveresponse2 = '';
          }, 5000);
        });
    }
  }

  ngOnInit(): void {}
}
