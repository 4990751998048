import { CursosService } from './../../../services/cursos.service';
import { Component, OnInit } from '@angular/core';
import { ClasesService } from '../../../services/clases.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserService } from '../../../services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthenticationService } from '../../../services/authentication.service';
import { Colegio } from '../../../interfaces/colegio';
import { ColegiosService } from '../../../services/colegios.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-creargrupo',
  templateUrl: './creargrupo.component.html',
  styleUrls: ['./creargrupo.component.css'],
})
export class CreargrupoComponent implements OnInit {
  nombre: string;
  descripcion: string ='';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  picture: any;
  currentuid: string;
  saveresponse: string;
  userUid: any;
  userdata: any;
  colegio: Colegio;
  curso: any;
  results: any;
  searchValue: string;
  userlist = [];
  userlisids = [];
  userindatabase: any; /* datos del usuario en la coleccion usuarios */
  cursos: any; /* nombres de cursos */
  saving = false;
  clicked = false;
  changeFoto: boolean = false;
  miscursos: any[];
  cursoForm: string;
  datosCurso: any;

  constructor(
    private clasesService: ClasesService,
    private authenticationService: AuthenticationService,
    private firebaseStorage: AngularFireStorage,
    private colegioService: ColegiosService,
    private CursosService: CursosService,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.userdata = Userdata.payload.doc.data();
      this.userindatabase =
        Userdata; /* datos del usuario en coleccion usuarios */
      this.getColegio(
        this.userdata
      ); /* llama a la funcion para obtener el colegio */
      // this.getCursos();
      this.search();
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario, uid en authentication */

        this.userService.getUserById(this.userUid).subscribe((userdata) => {
          if (userdata[0]) {
            this.userdata = userdata[0].payload.doc.data();
            this.userindatabase =
              userdata[0]; /* datos del usuario en coleccion usuarios */
            this.getColegio(
              this.userdata
            ); /* llama a la funcion para obtener el colegio */
            // this.getCursos();
            this.search();
          }
        });
      }
    });
  }

  /* obtiene el primer colegio(array) al que pertenezca el usuario logueado */
  getColegio(userdata) {
    if (userdata && userdata.colegios[0]) {
      this.colegioService
        .getColegioById(userdata.colegios[0])
        .subscribe((colegio) => {
          if (colegio) {
            this.colegio = colegio.payload.data(); /* informacion del colegio */
            //console.log(this.colegio);
            this.cursos = [];
            this.colegio.cursos.forEach((elcurso) => {
              //console.log(elcurso);
              this.CursosService.getCursosById(elcurso).subscribe(
                (cursodata) => {
                  let elcursodata: any = cursodata.payload.data();
                  //console.log(elcursodata);
                  this.cursos.push(elcursodata.nombre);
                  //console.log(this.cursos);
                }
              );
            });
          }
        });
    }
  }

  /* funcion para la busqueda de usuarios */
  search() {
    this.saving =
      false; /* impide que se muestre el listado por cambios en la base de datos */
    this.userService
      .searchUsersClases(this.userdata.clases)
      .subscribe((searchdata) => {
        this.results = searchdata;
      });
  }

  /* funcion para añadir usuarios haciendo click en el  boton +*/
  adduser(user) {
    if (
      !this.userlist.some(
        (el) => el.payload.doc.data().uid === user.payload.doc.data().uid
      )
    ) {
      /*comprueba que no exista en el array */
      this.userlist.push(user);
    }
  }

  /* funcion para quitar el usuario del array de invitados */
  removeuser(uid) {
    this.userlist.forEach((user) => {
      if (user.payload.doc.data().uid === uid) {
        this.userlist.splice(this.userlist.indexOf(user), 1);
      }
    });
  }

  /*image drop funciones */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.changeFoto = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  /* funcion para crear la clase */
  createclass() {
    this.saving = true; /* en true quiere decir que se esta creando una clase */
    this.saveresponse = ""
    /* validaciones, que no sean campos vacíos */
    if (this.nombre === '' || this.nombre === undefined) {
      this.translate
        .get('dashboard.grupos.crearC.response.nombre')
        .subscribe((data) => {
          this.saveresponse = data;
          return;
        });
        return;
    }

    if (this.userlist.length <= 0 || this.userlist.length === undefined) {
      this.translate
      .get('dashboard.grupos.crearC.response.miembro')
      .subscribe((data) => {
   
        this.saveresponse = data;
          return;
        });
        return;
    }

    this.clicked = true;
    /***************
     * validación ?
     * *************/

    /*this.curso = this.curso.replace(/[^01]+/gi, '');
    this.curso = this.curso.replace(/^0+/, '');

    this.nombre = this.nombre.replace(/[^01]+/gi, '');
    this.nombre = this.nombre.replace(/^0+/, '');

    if(this.descripcion !== undefined) {
      this.descripcion = this.descripcion.replace(/[^01]+/gi, '');
      this.descripcion = this.descripcion.replace(/^0+/, '');
    }*/

    if (this.croppedImage) {
      /* si hay avatar para subir */

      this.translate
        .get('dashboard.grupos.crearC.response.avatar')
        .subscribe((data) => {
          this.saveresponse = data;
        });
      const currenPictureId = Date.now();
      const pictures = this.firebaseStorage
        .ref('avatars/' + currenPictureId + '.jpg')
        .putString(this.croppedImage, 'data_url');
      pictures
        .then(() => {
          this.picture = this.firebaseStorage
            .ref('avatars/' + currenPictureId + '.jpg')
            .getDownloadURL();
          this.picture.subscribe((p) => {
            /* p es la url de la imagen subida */

            this.userlist.forEach((user) => {
              this.userlisids.push(
                user.payload.doc.data().uid
              ); /* array de solo uids de usuarios para guardar al crear la clase,
                                                                  uids en authentication */
            });

            if (!this.userlisids.some((el) => el === this.userUid)) {
              this.userlisids.push(
                this.userUid
              ); /* añade la uid del usuario actual al array que para crear la clase,
                                                uid en authentication */
            }

            const theclass = {
              administradores: [
                this.userUid,
              ] /* uid del usuario en authentication */,
              descripcion: this.descripcion,
              colegio: this.userdata.colegios[0],
              colegio_nombre: this.colegio.nombre,
              curso_nombre: 'Grupo: ' + this.nombre,
              imagen: p,
              nombre: this.nombre,
              usuarios:
                this.userlisids /* uids de los usuarios en authentication */,
              cursos: [],
            };

            this.clasesService
              .createdClase(theclass)
              .then((create) => {
                this.translate
                  .get('dashboard.grupos.crearC.response.creado')
                  .subscribe((data) => {
                    this.saveresponse = data;
                  });
                setTimeout(() => {
                  this.saveresponse = '';
                }, 5000);

                /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
                this.userlist.forEach((user) => {
                  /* se añade uid de la clase al array clase del usuario */
                  const clases = {
                    clases: firebase.firestore.FieldValue.arrayUnion(create.id),
                  };
                  /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
                  this.userService
                    .editUser(clases, user.payload.doc.id)
                    .then((edit) => {})
                    .catch((error) => {
                      console.log(error);
                    });
                });

                /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creado y por lo tanto
            no esta añadido al listado de invitados */
                const clases = {
                  clases: firebase.firestore.FieldValue.arrayUnion(create.id),
                };
                /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
                this.userService
                  .editUser(clases, this.userindatabase.payload.doc.id)
                  .then((edit) => {})
                  .catch((error) => {
                    console.log(error);
                  });

                /* despues de creada se redirecciona a mis grupos */
                this.router.navigate(['/dashboard/misgrupos']);
              })
              .catch((error) => {
                this.translate
                  .get('dashboard.grupos.crearC.response.error')
                  .subscribe((data) => {
                    this.saveresponse = data;
                  });

                setTimeout(() => {
                  this.saveresponse = '';
                }, 5000);
              });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      /* si no hay imagen para subir */

      this.userlist.forEach((user) => {
        this.userlisids.push(
          user.payload.doc.data().uid
        ); /* array de solo uids de usuarios para guardar al crear la clase,
                                                            uids en authentication */
      });

      if (!this.userlisids.some((el) => el === this.userUid)) {
        this.userlisids.push(
          this.userUid
        ); /* añade la uid del usuario actual al array que para crear la clase,
                                            uid en authentication */
      }

      const theclass = {
        administradores: [this.userUid] /* uid del usuario en authentication */,
        descripcion: this.descripcion,
        colegio: this.userdata.colegios[0],
        colegio_nombre: this.colegio.nombre,
        curso_nombre: 'Grupo: ' + this.nombre,
        imagen: '',
        nombre: this.nombre,
        usuarios: this.userlisids /* uids de los usuarios en authentication */,
        cursos: [],
      };

      this.clasesService
        .createdClase(theclass)
        .then((create) => {
          this.translate
            .get('dashboard.grupos.crearC.response.creado')
            .subscribe((data) => {
              this.saveresponse = data;
            });

          setTimeout(() => {
            this.saveresponse = '';
          }, 5000);

          /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
          this.userlist.forEach((user) => {
            /* se añade uid de la clase al array clase del usuario */
            const clases = {
              clases: firebase.firestore.FieldValue.arrayUnion(create.id),
            };
            /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
            this.userService
              .editUser(clases, user.payload.doc.id)
              .then((edit) => {})
              .catch((error) => {
                console.log(error);
              });
          });

          /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creado y por lo tanto
          no esta añadido al listado de invitados */
          const clases = {
            clases: firebase.firestore.FieldValue.arrayUnion(create.id),
          };
          /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
          this.userService
            .editUser(clases, this.userindatabase.payload.doc.id)
            .then((edit) => {})
            .catch((error) => {
              console.log(error);
            });

          /* despues de creada se redirecciona a mis grupos */
          this.router.navigate(['/dashboard/misgrupos']);
        })
        .catch((error) => {

          this.translate
          .get('dashboard.grupos.crearC.response.error')
          .subscribe((data) => {
            this.saveresponse = data;
          });

        
          console.log(error);
          setTimeout(() => {
            this.saveresponse = '';
          }, 5000);
        });
    }
  }

  //DESDE AQUI SERIA SELECCIONANDO CURSO

  // /* funcion para crear la clase */
  // createclass() {

  //   this.saving = true; /* en true quiere decir que se esta creando una clase */

  //   /* validaciones, que no sean campos vacíos */
  //   if(this.nombre === '' || this.nombre === undefined) {
  //     this.saveresponse = "dashboard.grupos.crearC.response.nombre";
  //     return;
  //   }

  //   if(this.userlist.length <= 0 || this.userlist.length === undefined) {
  //     this.saveresponse = 'dashboard.grupos.crearC.response.miembro';
  //     return;
  //   }

  //   /***************
  //    * validación ?
  //    * *************/

  //   /*this.curso = this.curso.replace(/[^01]+/gi, '');
  //   this.curso = this.curso.replace(/^0+/, '');

  //   this.nombre = this.nombre.replace(/[^01]+/gi, '');
  //   this.nombre = this.nombre.replace(/^0+/, '');

  //   if(this.descripcion !== undefined) {
  //     this.descripcion = this.descripcion.replace(/[^01]+/gi, '');
  //     this.descripcion = this.descripcion.replace(/^0+/, '');
  //   }*/

  //   this.recogerCurso().then(() => {
  //     if (this.croppedImage) {
  //       /* si hay avatar para subir */

  //       this.saveresponse = 'dashboard.grupos.crearC.response.avatar'; /* notifica arriba del boton guardar */
  //       const currenPictureId = Date.now();
  //       const pictures = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').putString(this.croppedImage, 'data_url');
  //       pictures.then(() => {
  //         this.picture = this.firebaseStorage.ref('avatars/' + currenPictureId + '.jpg').getDownloadURL();
  //         this.picture.subscribe((p)=>{ /* p es la url de la imagen subida */

  //           this.userlist.forEach((user) => {
  //             this.userlisids.push(user.payload.doc.data().uid); /* array de solo uids de usuarios para guardar al crear la clase,
  //                                                                   uids en authentication */
  //           });

  //           if(!this.userlisids.some(el => el === this.userUid)) {
  //             this.userlisids.push(this.userUid); /* añade la uid del usuario actual al array que para crear la clase,
  //                                                 uid en authentication */
  //           }
  //             const theclass = {
  //               administradores: [
  //                 this.userUid,
  //               ] /* uid del usuario en authentication */,
  //               descripcion: this.descripcion,
  //               colegio: this.userdata.colegios[0],
  //               colegio_nombre: this.colegio.nombre,
  //               curso_nombre: this.datosCurso.nombre,
  //               imagen: p,
  //               nombre: this.nombre,
  //               usuarios:
  //                 this.userlisids /* uids de los usuarios en authentication */,
  //               cursos: [this.cursoForm],
  //             };

  //             this.clasesService
  //               .createdClase(theclass)
  //               .then((create) => {
  //                 this.datosCurso.clases.push(create.id);

  //                 this.CursosService.editCurso(
  //                   this.cursoForm,
  //                   this.datosCurso
  //                 ).then(() => {

  //                   this.saveresponse = 'dashboard.grupos.crearC.response.creado';
  //                   setTimeout(() => {this.saveresponse = ''; }, 5000);

  //                   /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
  //                   this.userlist.forEach((user) => {
  //                     /* se añade uid de la clase al array clase del usuario */
  //                     const clases = {
  //                       clases: firebase.firestore.FieldValue.arrayUnion(
  //                         create.id
  //                       ),
  //                     };
  //                     /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
  //                     this.userService
  //                       .editUser(clases, user.payload.doc.id)
  //                       .then((edit) => {})
  //                       .catch((error) => {
  //                         console.log(error);
  //                       });
  //                   });

  //                   /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creado y por lo tanto
  //             no esta añadido al listado de invitados */
  //                   const clases = {
  //                     clases: firebase.firestore.FieldValue.arrayUnion(create.id),
  //                   };
  //                   /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
  //                   this.userService
  //                     .editUser(clases, this.userindatabase.payload.doc.id)
  //                     .then((edit) => {})
  //                     .catch((error) => {
  //                       console.log(error);
  //                     });

  //                   /* despues de creada se redirecciona a mis grupos */
  //                   this.router.navigate(['/dashboard/misgrupos']);
  //                 });
  //               })
  //               .catch((error) => {
  //                 this.saveresponse = 'dashboard.grupos.crearC.response.error';
  //                 setTimeout(() => {this.saveresponse = ''; }, 5000);
  //               });
  //           });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       /* si no hay imagen para subir */

  //       this.userlist.forEach((user) => {
  //         this.userlisids.push(
  //           user.payload.doc.data().uid
  //         ); /* array de solo uids de usuarios para guardar al crear la clase,
  //                                                           uids en authentication */
  //       });

  //       if (!this.userlisids.some((el) => el === this.userUid)) {
  //         this.userlisids.push(
  //           this.userUid
  //         ); /* añade la uid del usuario actual al array que para crear la clase,
  //                                           uid en authentication */
  //       }

  //       const theclass = {
  //         administradores: [
  //           this.userUid,
  //         ] /* uid del usuario en authentication */,
  //         descripcion: this.descripcion,
  //         colegio: this.userdata.colegios[0],
  //         colegio_nombre: this.colegio.nombre,
  //         curso_nombre: this.datosCurso.nombre,
  //         imagen: '',
  //         nombre: this.nombre,
  //         usuarios:
  //           this.userlisids /* uids de los usuarios en authentication */,
  //         cursos: [this.cursoForm],
  //       };

  //       this.clasesService
  //         .createdClase(theclass)
  //         .then((create) => {

  //           this.datosCurso.clases.push(create.id);
  //           this.CursosService.editCurso(
  //             this.cursoForm,
  //             this.datosCurso
  //           ).then(() => {
  //             this.saveresponse = 'dashboard.grupos.crearC.response.creado';
  //                   setTimeout(() => {this.saveresponse = ''; }, 5000);

  //             /* recorre el listado de usuarios para actualizarlos y añadir la nueva clase a su lista de clases */
  //             this.userlist.forEach((user) => {
  //               /* se añade uid de la clase al array clase del usuario */
  //               const clases = {
  //                 clases: firebase.firestore.FieldValue.arrayUnion(create.id),
  //               };
  //               /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
  //               this.userService
  //                 .editUser(clases, user.payload.doc.id)
  //                 .then((edit) => {})
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             });

  //             /* aqui se actualiza y añade la uid de la clase en el usuario que la esta creado y por lo tanto
  //           no esta añadido al listado de invitados */
  //             const clases = {
  //               clases: firebase.firestore.FieldValue.arrayUnion(create.id),
  //             };
  //             /* para actualizar el usuario se usa la uid del usuario en la coleccion usuarios */
  //             this.userService
  //               .editUser(clases, this.userindatabase.payload.doc.id)
  //               .then((edit) => {})
  //               .catch((error) => {
  //                 console.log(error);
  //               });

  //             /* despues de creada se redirecciona a mis grupos */
  //             this.router.navigate(['/dashboard/misgrupos']);
  //           })

  //         })
  //         .catch((error) => {
  //           this.saveresponse = 'dashboard.grupos.crearC.response.error';
  //           setTimeout(() => {this.saveresponse = ''; }, 5000);
  //         });
  //     }
  //   });
  // }

  // getCursos() {
  //   if (this.userdata && this.userdata.colegios[0]) {
  //     this.miscursos = [];
  //     this.userdata.colegios.forEach((colegio: any) => {
  //       var observable = this.CursosService.getCursosByColegioidOrdenado(
  //         colegio
  //       ).subscribe((cursos) => {
  //         cursos.forEach((curso) => {
  //           this.miscursos.push(curso.payload.doc);
  //           this.cursoForm = curso.payload.doc.id;
  //           this.selectCurso(this.cursoForm)
  //         });
  //       });
  //     });
  //   }
  // }
  // selectCurso(cole) {

  //   this.colegioService.getColegioByCursoId(this.cursoForm).subscribe((colegio)=>{
  //     colegio.forEach(cole=>{
  //       this.colegio=cole.data();

  //     })
  //   })
  //   // this.colegio = cole
  // }

  // async recogerCurso() {
  //   await this.miscursos.forEach((curso) => {
  //     if (curso.id == this.cursoForm) {
  //       this.datosCurso = curso.data();
  //     }
  //   });
  // }
}
